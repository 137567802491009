<!--
 * @Author: lbh
 * @Date: 2021-04-28 17:16:18
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-29 16:02:41
 * @Description: GSA 手機點餐系統
-->
<template>
  <div
    class="gsa-box t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="products"
      child='gsa'
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="GSA 手機點餐系統"
        :type="3"
        :wtype="window.type"
        :image="getImgPath('pbannerzz.jpg')"
        :bgImage="getImgPath('b2-1.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :bImg="graphic.bImg"
        :datas="graphic.datas"
        :height="graphic.height"
        title="GSA 手機點餐系統"
      />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        bImg: '',
        height: 0
      }
    }
  },
  mounted () {
    util.setTitle('GSA 手機點餐系統 – 薑軟件有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 初始化數據
    let imgs = [util.getImgPath('mobile_order.png')];
    this.graphic.imgs = imgs;
    let datas = [
      {
        title: 'GSA 手機點餐系統功能特點', items: [
          { title: '– 簡易操作, 容易上手' },
          { title: '– 支援多國語言' },
          { title: '– 餐牌更新提醒' },
          { title: '– 支持印單, 打印上菜紙等功能' },
          { title: '– 簡單的滑動操作，使屏幕可顯示最多的內容' },
          { title: '– 點餐介面排版靈活設定' },
          { title: '– 轉檯, 連檯, 分檯' },
          { title: '– 系統一鍵升級' },
        ],
      },
      {
        title: '直接落單點餐', items: [
          { title: '當你為客人點餐時， 若配備了GSA手機點餐系統， 員工便可直接使用手機為客人點餐， 不用 “寫紙頭”， 更不需要每次都前往POS系統主機進行點餐程序，省時方便，有效率地完成點餐程序，增加翻枱次數。' }
        ],
      },
      {
        title: "減低出錯機會", items: [
          { title: "傳統 “寫紙頭” 點餐，由於很多時由樓面員工負責 “寫紙頭” 點餐，再交由特定員工於POS點餐，常常會遇到看不清楚紙頭上寫的是甚麼字，或樓面員工寫錯單，增加不少出錯機會，而使用手機系統點餐，可即時從手機上看到點餐的列表，還可以在客人點餐完成後，即時向客人複述點餐內容，將出錯率減到最低。" }
        ],
      },
    ];
    this.graphic.datas = datas;
    let bImg = util.getImgPath('pd1.jpg');
    this.graphic.bImg = bImg;
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.2);
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 1.4;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 1.4;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 1.4;
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
